// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  font-family: 'Rubik', sans-serif;
  background: #F7F8FA;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
  }
}

.btn.btn-primary {
  color: #FFFFFF;
  background-color: #4d7cfe;
  border-color: #4d7cfe;
}

.brand-logo img {
  width: 180px;
}
svg#SvgjsSvg1001 + .fade.modal-backdrop.show + .modal + iframe,
svg#SvgjsSvg1001 + iframe  {
  display: none !important;
  position: static !important;
  height: 0 !important;
  width: 0 !important;
  z-index: -1 !important;
}
.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}
span.active {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #a94442;
  border-radius: 50px;
}
span.deactive {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #31708f;
  border-radius: 50px;
}
.viewEmp {
  ul {
    padding-left: 15px;
    list-style: none;
    li {
      font-size: 15px;
      label {
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }
}

.btnGroup {
  button.btn.btn-primary.active {
    background-color: #a94442 !important;
    border: 1px solid #a94442 !important;
  }
  button.btn.btn-primary {
    min-height: 34px !important;
    height: 34px !important;
    line-height: 20px;
    font-size: 16px !important;
    font-weight: 400 !important;
    h5 {
      font-weight: 400 !important;
      margin-bottom: 0;
      font-size: 16px !important;
    }
  }
}
.selectTeam {
  button.dropdown-toggle {
    border: 1px solid #778CA2 !important;
    color: #778CA2 !important;
    margin-bottom: 15px !important;
    width: auto;
    text-align: left;
    font-size: 16px;
    text-transform: capitalize;
    &:hover {
      border: 1px solid #778CA2;
      color: #778CA2;
      margin-bottom: 15px !important;
      width: auto;
      text-align: left;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .dropdown-menu {
    max-height: 160px;
    overflow-y: auto;
    width: 100%;
    a.dropdown-item {
      font-size: 14px;
    }
}
}
.table-bordered.usersTable {
  th, td {
    vertical-align: middle;
    font-size: 14px;
    text-transform: capitalize;
  }
}
button.btn.btn-primary.excelBtn {
  margin-right: 15px;

  img {
    margin-right: 10px;
  }
}

.form-group {
  label {
    font-size: 14px;
    font-weight: 500;
    color: #3F4254;
  }
}

input.form-control {
  height: 46px;
  min-height: 46px;
  font-size: 14px !important;
}

.btn.btn-primary {
  min-height: 46px;
  font-size: 14px;
}

form {

  input.form-control,
  select.form-control {
    height: 46px;
    min-height: 46px;
    font-size: 14px !important;
  }

  button.btn.btn-secondary.closeBtn,
  button.btn.btn-secondary,
  .btn.btn-primary {
    padding: 10px 25px;
    font-size: 14px;
  }

  button.btn.btn-secondary {
    margin-right: 15px;
  }
}

.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn.btn-primary.focus:not(.btn-text) {
  background-color: #4d7cfe;
  border-color: #4d7cfe;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn.btn-primary.dropdown-toggle,
.show .btn.btn-primary.btn-dropdown {
  background-color: #4d7cfe;
  border-color: #4d7cfe;
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary  !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.aside {
  background-color: $white;
}

.brand-dark .brand {
  background-color: $white;
}

.aside-menu {
  background-color: $white;

  .menu-nav>.menu-item>.menu-link .menu-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #778CA2;
  }

  .menu-nav>.menu-section .menu-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #98A9BC;
  }

  .menu-nav>.menu-item.menu-item-open>.menu-link {
    background-color: #4D7CFE;
  }

  .menu-nav>.menu-item.menu-item-active>.menu-heading,
  .menu-nav>.menu-item.menu-item-active>.menu-link {
    background-color: $white;

    .menu-item.menu-item-active>.menu-heading .menu-text,
    .menu-item.menu-item-active>.menu-link .menu-text {
      color: #ffffff;
    }
  }

  .menu-nav>.menu-item>.menu-link {
    padding: 14px 24px;
  }

  .menu-nav>.menu-item.menu-item-active>.menu-link {
    background-color: #4D7CFE;
  }

  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading,
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link {
    background-color: #4D7CFE !important;
  }

  .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
  .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #ffffff;
  }

  .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #ffffff;
  }

}

.svg-icon.svg-icon-primary svg g [fill] {
  transition: fill 0.3s ease;
  fill: #778CA2 !important;
}

.symbol.symbol-light-success .symbol-label {
  background-color: #4d7cfe42;
  color: #4D7CFE;
  border-radius: 50px;
}
a.menu-link span.svg-icon.menu-icon {
  background: url('assets/images/sidebarLeft.png');
  width: 50px;
  height: 25px;
  transition: 0.5s all ease-in-out;
}
.dashboardLink a.menu-link span.svg-icon.menu-icon {
  background-position: 42px -9px;
}
li.dashboardLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .dashboardLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon {
  background-position: 0px -9px;
}

li.categoryLink.menu-item a.menu-link span.svg-icon.menu-icon {
  background-position: 40px -51px;
}
li.categoryLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .categoryLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: 0px -51px; 
}

li.subCategoryLink.menu-item a.menu-link span.svg-icon.menu-icon { 
  background-position: 40px -95px;
  height: 28px;
}
li.subCategoryLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .subCategoryLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: -5px -95px;
}
li.tagLink.menu-item a.menu-link span.svg-icon.menu-icon { 
  background-position: 36px -142px;
}
li.tagLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .tagLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: -5px -142px;
}
li.fabricLink.menu-item a.menu-link span.svg-icon.menu-icon { 
  background-position: 45px -188px;
}
li.fabricLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .fabricLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: -4px -188px;
}
li.colorLink.menu-item a.menu-link span.svg-icon.menu-icon { 
  background-position: 40px -233px;
}
li.colorLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .colorLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: -4px -233px;
}
li.seasonLink.menu-item a.menu-link span.svg-icon.menu-icon { 
  background-position: 40px -280px;
}
li.seasonLink.menu-item:hover a.menu-link span.svg-icon.menu-icon,
.aside-menu .menu-nav > .seasonLink.menu-item.menu-item-active > .menu-link.active span.svg-icon.menu-icon { 
  background-position: -4px -280px;
}
button.brand-toggle {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .brand-dark .brand {
    background-color: $white;
  }

  .header .header-menu .menu-nav>.menu-item>.menu-link .menu-text {
    color: #6c7293;
    font-weight: 500;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
  }

  .header .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text,
  .header .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text {
    color: #4D7CFE;
  }

  .menu-item.menu-item-active>.menu-link .menu-text {
    color: #4D7CFE;
  }

  .header .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text,
  .header .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text {
    color: #4D7CFE;
  }
}

.closeBtn {
  margin-right: 12px;
}

@media (max-width: 768px) {
  .offcanvas {
    width: auto !important;
  }

  .secndWrap {
    .IncomeStatusWrap {
      width: 100%;
      display: block !important;

      .incomBox {
        background: #FFFFFF;
        border-radius: 4px;
        width: 100% !important;
        margin-right: 20px;
        height: 365px;
        margin-bottom: 30px;
      }

      .projectBox {
        background: #FFFFFF;
        border-radius: 4px;
        width: 100% !important;
        margin-right: 0px;
        height: 365px;
      }

    }

    .eventsWrap {
      height: auto !important;

      .calenderWrap {
        display: block !important;
        width: 100% !important;
        position: relative;

        .calender {
          width: 100% !important;
          padding: 30px;
        }
      }

      .eventslists {
        width: 100% !important;
        height: auto !important;
        border-left: 0px solid #DFDFDF !important;
        overflow-y: scroll;
      }
    }

    .invoiceWrap {
      background: #FFFFFF;
      border-radius: 4px;
      height: 590px;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .projectTimelineWrap .timelineBody .listWrap .userDetails div .updateBtn {
      background-color: #4d7cfe42 !important;
      border: 0;
      height: 36px;
      padding: 10px 16px !important;
      margin-right: 8px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 17px;
      text-align: center;
      color: #4D7CFE !important;
    }

    .projectTimelineWrap .timelineBody .listWrap .userDetails div p.textGray {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 17px;
      text-align: center;
      color: #66676C;
      margin: 0;
    }
  }
}


/* Pagination CSS Start */
.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul.pagination {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0px;

    li.disabled {
      pointer-events: flex;
      cursor: default;

      a {
        opacity: 0.3;
      }
    }

    li {
      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(32);
        text-align: center;
        letter-spacing: 0.21px;
        color: #252631;
        padding: 5px;
        margin: 0px 3px;
        text-decoration: flex;
        cursor: pointer;

        &:hover {
          background: #4D7CFE;
          border-radius: 4px;
          color: #fff;
        }
      }

      a.leftArrow,
      a.rightArrow {
        width: auto;
        height: auto;
        padding: 0px;
        margin: 0px 25px;

        &:hover {
          background: transparent;
          color: inherit;
        }
      }

      a.leftArrow {
        img {
          margin-right: 10px;
        }
      }

      a.rightArrow {
        img {
          transform: rotate(180deg);
          margin-left: 10px;
        }
      }

      a.active {
        background: #4D7CFE;
        border-radius: 4px;
        color: #fff;
      }
    }

  }

  .pageCount {
    h3 {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(17);
      text-transform: uppercase;
      color: #778CA2;
    }
  }
}

.modal-header {
  border-bottom: none;
}
.modal-body {
  padding: 20px;

  p {
      //font-family: $fontFamilyRubik;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #eee;
      max-width: 350px;
      margin: 0 auto;
  }

  .importWrapOuter {
      min-height: 46px;
      border-radius: 4px;
      border: 1px solid #e8ecef;
      color: #98a9bc;
    //  font-family: $fontFamilyRubik;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      -webkit-appearance: none;
      background-image: none  !important;
      padding: 0;
      display: flex;
      align-items: center;

      .importWrapInner {
          min-height: 46px;
          display: flex;
          align-items: center;
          background-color: #f2f4f6;
          border: 1px solid #e8ecef;
          border-top: none;
          border-left: none;
          border-bottom: none;
          padding: 0 30px;
          position: relative;
          cursor: pointer;

          label {
              margin-bottom: 0;
              cursor: pointer;
          }

          input#formFile {
              position: absolute;
              opacity: 0;
              min-height: 46px;
              left: 0px;
              cursor: pointer;
          }
      }
  }

  span.form-text.text-muted {
      font-size: 11px;
  }
}

/* Media Query STart  */
@media only screen and (max-width: 991.98px) {
  .brand-dark .header-mobile {
    background-color: #fff;
  }
}

@media only screen and (max-width: 480px) {

  .paginationWrapper ul.pagination li a.leftArrow,
  .paginationWrapper ul.pagination li a.rightArrow {
    margin: 0px 5px;
  }

  .paginationWrapper ul.pagination li a {
    width: 25px;
    height: 25px;
  }

  .card.card-custom>.card-body {
    padding: 15px;
  }

  .card-header {
    padding-left: 15px;
    padding-right: 15px;
  }
}